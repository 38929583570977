import React from "react"
// mui
import {Avatar, Box, Button, ButtonBase, Divider, Paper, Stack, Typography} from "@mui/material";
import {Person, QrCode2} from "@mui/icons-material";
// components
import {VCardExternalLinksMap, VCardSeparator} from "../../index";
// functions
import {getLanguage, getUserAgent} from "../../../../../functions";


export default function MainCard(props: any) {

    const {
        styles,
        t,
        templateData,
        vcardData,
        template,
        preview,
        setShowContactForm,
        setShowQrCode,
        minimalist,
        noSeparator,
        downloadVcf,
        downloadApplePass,
        downloadGooglePass
    } = props;

    const userAgent = getUserAgent()

    const renderThirdPartyButtons = () => {

        if (templateData?.templateType === "PRODUCT" || template.buttons.displayWallet === false) return <></>

        const buttonStyle = {
            mx: 1,
            my: 2,
            bgcolor: "transparent",
            "&:hover": {
                bgcolor: "transparent"
            }
        }

        // if userAgent.device.vendor is "undefined" this means the user uses a desktop computer.
        // refer to specific issues on github for documentation about this caveat https://github.com/faisalman/ua-parser-js/issues/224

        const deviceOs = userAgent.os.name
        const language = getLanguage().slice(0,2)

        let languageSrc: any

        if (language !== "es" && language !== "fr" && language !== "en") languageSrc = "en"
        else languageSrc = language

        switch (deviceOs) {
            case "iOS":
            case "Mac OS":
                let appleWalletSrc = `/assets/buttons/apple-${languageSrc}.svg`
                return (
                    <ButtonBase
                        sx={buttonStyle}
                        onClick={() => {
                            if (preview) { return; }
                            downloadApplePass()
                        }}
                    >
                        <img
                            src={appleWalletSrc}
                            width={113}
                            height={35}
                            alt={"apple wallet"}
                            style={{
                                width: "auto",
                                minHeight: 48
                            }}
                        />
                    </ButtonBase>
                )
            default:
                let googleWalletSrc = `/assets/buttons/google-${languageSrc}.svg`
                return (
                    <ButtonBase
                        sx={{...buttonStyle, mx: "auto!important" }}
                        onClick={() => {
                            if (preview) { return; }
                            downloadGooglePass()
                        }}
                    >
                        <img
                            src={googleWalletSrc}
                            width={298}
                            height={50}
                            alt={"google pay"}
                            style={{
                                width: "100%",
                                height: "auto"
                            }}
                        />
                    </ButtonBase>
                )
        }
    }

    return (
        <Paper sx={{...styles.paper, p: 0, mt: 0}} className={"mainPaper"}>

            <Box>
                <Box sx={styles.banner} />
                {
                    !noSeparator && <VCardSeparator type={template.separator} color={template.backgroundInt.backgroundColor} />
                }
            </Box>

            <Box sx={styles.bannerStack}>
                <Stack>
                    <Avatar
                        sx={styles.avatar}
                        src={vcardData.picture}
                    >
                        {/* FALLBACK */}
                        <Person sx={styles.fallbackAvatar} />
                    </Avatar>
                    <Typography component={"h1"} align={"center"} sx={styles.name}>
                        {(vcardData.title !== "not_displayed" && vcardData.title !== "none" && vcardData.title) && t(vcardData.title)} {vcardData.firstName} {vcardData.lastName}
                    </Typography>
                    <Typography component={"h2"} align={"center"} sx={styles.title}>
                        {vcardData.jobTitle}
                    </Typography>
                    {
                        vcardData.jobTitleAdditional &&
                        <Typography component={"h2"} align={"center"} sx={styles.title}>
                            {vcardData.jobTitleAdditional}
                        </Typography>
                    }
                    {
                        !!vcardData.businessEntity &&
                        <Typography component={"h2"} align={"center"} sx={styles.title}>
                            {vcardData.businessEntity}
                        </Typography>
                    }
                    <Divider sx={styles.mainCardDivider} />
                </Stack>
            </Box>

            <Box>
                <Stack spacing={1}>
                    <Box>
                        <VCardExternalLinksMap
                            templateLinks={templateData.information.socialNetworks}
                            vcardLinks={vcardData.information.socialNetworks}
                            styles={styles}
                            preview={preview}
                        />
                    </Box>
                    {
                        !minimalist ?
                        <>
                            <Box sx={{...styles.alignButtonCenter, mb: 3}}>
                                <Button
                                    sx={{...styles.buttons, ...styles.topQRCodeButton}}
                                    onClick={() => {
                                        if (preview) { return; }
                                        setShowQrCode(true)
                                    }}
                                    startIcon={<QrCode2 />}
                                >
                                    {t("vcard_my_qrcode")}
                                </Button>
                            </Box>

                            <Box sx={{ textAlign: "center" }}>
                                {renderThirdPartyButtons()}
                            </Box>

                            <Button
                                fullWidth
                                sx={{...styles.buttons, ...styles.contactButton}}
                                onClick={() => {
                                    if (preview) { return; }
                                    setShowContactForm(true)
                                }}
                            >
                                {t("vcard_contact_me")}
                            </Button>
                            <Button
                                fullWidth
                                size={"large"}
                                sx={{...styles.buttons, ...styles.addMeButton}}
                                onClick={downloadVcf}
                            >
                                {t("vcard_add_me")}
                            </Button>
                        </>
                        :
                        renderThirdPartyButtons()
                    }
                </Stack>
            </Box>
        </Paper>
    )
}
