import React from "react"
// mui
import {Paper, Typography} from "@mui/material";
// components
import {SectionAction, SectionCollapse} from "../../index";
// actions
import {callMe, emailMe} from "../../../Actions/VcardsActions";

export default function PersonalInformation(props: any) {

    const {styles, t, vcardData, preview, plain, defaultExpanded, templateData} = props;

    if (vcardData.information.contact && (
        vcardData.information.contact.phone !== "" ||
        vcardData.information.contact.secondaryPhone !== "" ||
        vcardData.email !== ""
    )) {
        return (
            <Paper sx={styles.paper}>
                <SectionCollapse
                    type={"personal"}
                    title={templateData.templateType === "PRODUCT" ? t( "product_information") : t("vcard_personal_informations")}
                    templateType={templateData.templateType}
                    {...{styles, plain, defaultExpanded, t}}
                >
                    <SectionAction
                        action={() => {
                            if (preview) return
                            callMe(vcardData.information.contact.secondaryPhone)
                        }}
                        styles={styles}
                        dataName={t("phone")}
                        dataContent={vcardData.information.contact.secondaryPhone}
                    />
                    <SectionAction
                        action={() => {
                            if (preview) return
                            callMe(vcardData.information.contact.phone)
                        }}
                        styles={styles}
                        dataName={t("phone")}
                        dataContent={vcardData.information.contact.phone}
                    />
                    <SectionAction
                        action={() => {
                            if (preview) return
                            emailMe(vcardData.email)
                        }}
                        styles={styles}
                        dataName={t("email")}
                        dataContent={vcardData.email}
                    />
                    {
                        (
                            vcardData.information.contact.other &&
                            vcardData.information.contact.other.length > 0
                        ) && vcardData.information.contact.other.map((contact: any, index: number) => (
                            <div key={index}>
                                <Typography sx={styles.actionTitle}>
                                    {contact.name}
                                </Typography>
                                <Typography sx={styles.text}>
                                    <b>{contact.id}</b>
                                </Typography>
                            </div>
                        ))

                    }
                </SectionCollapse>
            </Paper>
        )
    }

    return <></>
}
