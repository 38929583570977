import { createRoot } from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
// Translation
import './i18n';
// app
import App from './App';

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

const client: any = new ApolloClient({
    uri: `${process.env.REACT_APP_API_GRAPHQL_URL}/vcards`,
    cache: new InMemoryCache()
});

root.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>
);

