import React from "react"
import {TemplateClassic, TemplateMinimalist, TemplateModern, TemplateService, TemplateTower} from "../Templates";

interface ProviderProps {
    preview?: boolean,
    template?: any,
    vcard?: any,
    loading?: boolean,
    downloadVcf: () => void,
    downloadApplePass: () => void
    downloadGooglePass: () => void
}

// vcard.user
// const placeholderVcardData = {
//     title: "",
//     lastName: "Unitag",
//     firstName: "",
//     jobTitle: "vCard",
//     picture: "",
//     email: "email@email.com",
//     information: {
//         contact: {
//             phone: "+33102030405",
//             secondaryPhone: "+33102030405",
//             other: [
//                 { name: "", id: "" }
//             ]
//         },
//         biography: "",
//         medias: {
//             youtubeUrl: "",
//             custom: [
//                 {
//                     title: "",
//                     fileName: "",
//                     customUrl: "",
//                     mediaGroupName: ""
//                 }
//             ],
//         },
//         office: {
//             address: "",
//             address2: "",
//             city: "",
//             postCode: "",
//             country: "",
//             phone: "",
//             email: "",
//             website: ""
//         },
//         sections: [
//             {
//                 name: "",
//                 profiles: [
//                     {
//                         firstName: "",
//                         lastName: "",
//                         phone: "",
//                         email: "",
//                         title: "none",
//                         jobTitle: ""
//                     }
//                 ]
//             }
//         ],
//         socialNetworks: []
//     }
// }

export default function VcardsProvider(props: ProviderProps) {

    const {template, vcard, downloadVcf, downloadApplePass, downloadGooglePass} = props;

    const product = template.templateType === "PRODUCT"

    const returnVcardType = () => {

        if (vcard) {
            switch (template.base) {
                case "modern": return <TemplateModern
                    template={template.customisation}
                    templateData={template}
                    vcardData={vcard.user}
                    uuid={vcard.uuid}
                    qrcodeUrl={vcard.qrcodePreviewUrl}
                    {...{downloadVcf, downloadApplePass, downloadGooglePass, product}}
                />
                case "classic": return <TemplateClassic
                    template={template.customisation}
                    templateData={template}
                    vcardData={vcard.user}
                    uuid={vcard.uuid}
                    qrcodeUrl={vcard.qrcodePreviewUrl}
                    {...{downloadVcf, downloadApplePass, downloadGooglePass, product}}
                />
                case "tower": return <TemplateTower
                    template={template.customisation}
                    templateData={template}
                    vcardData={vcard.user}
                    uuid={vcard.uuid}
                    qrcodeUrl={vcard.qrcodePreviewUrl}
                    {...{downloadVcf, downloadApplePass, downloadGooglePass, product}}
                />
                case "minimalist": return <TemplateMinimalist
                    template={template.customisation}
                    templateData={template}
                    vcardData={vcard.user}
                    uuid={vcard.uuid}
                    qrcodeUrl={vcard.qrcodePreviewUrl}
                    {...{downloadVcf, downloadApplePass, downloadGooglePass, product}}
                />
                case "service": return <TemplateService
                    template={template.customisation}
                    templateData={template}
                    vcardData={vcard.user}
                    uuid={vcard.uuid}
                    qrcodeUrl={vcard.qrcodePreviewUrl}
                    {...{downloadVcf, downloadApplePass, downloadGooglePass, product}}
                />
                default: return
            }
        }

        return <></>
    }

    return (
        <>
            {returnVcardType()}
        </>
    )
}
