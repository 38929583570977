import React from "react"
import {Container, Grid, Stack, Typography} from "@mui/material";
import {Cancel} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export default function Error(props: any) {

    const {t} = useTranslation(["common"])

    const {
        vcardLoadingError,
        pathError,
        emptyUuid
    } = props

    return (
        <>
            <Container maxWidth={false} sx={{ pt: 10 }}>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12}>
                        <Stack spacing={2} sx={{ textAlign: "center" }}>
                            <Cancel sx={{ mx: "auto", fontSize: 38 }} color={"error"} />
                            <Typography color={"error"} variant={"h2"} component={"h1"}>
                                {
                                    vcardLoadingError ? t("vcard_loading_error")
                                        : pathError ? t("path_error")
                                            : emptyUuid ? t("empty_uuid_error")
                                                : t("error")}
                            </Typography>
                            <Typography>
                                {vcardLoadingError ? t("vcard_loading_error_text")
                                    : pathError ? t("path_error_text")
                                        : emptyUuid ? t("empty_uuid_error_text")
                                            : t("error_text")}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}