import {gql} from "@apollo/client";

const GET_FULL_VCARD = gql`
    query publicGetVcard($vcardUuid: UUID, $legacyId: String) {
        publicGetVcard(vcardUuid: $vcardUuid, legacyId: $legacyId) {
            uuid
            url
            qrcodePreviewUrl
            qrcodeUrl
            template {
                base
                qrcodeTemplateUuid
                templateType
                company {
                    name
                    options {
                        contactFormat
                        contactFormComment
                    }
                }
                customisation {
                    backgroundExt {
                        backgroundColor
                        isGradient
                        gradientColor1
                        gradientColor2
                    }
                    backgroundInt {
                        backgroundColor
                        isGradient
                        gradientColor1
                        gradientColor2
                    }
                    fontNameHolder {
                        fontName
                        fontColor
                    }
                    fontCardTitles {
                        fontName
                        fontColor
                    }
                    fontCardTexts {
                        fontName
                        fontColor
                    }
                    buttons {
                        font {
                            fontName
                            fontColor
                        }
                        backgroundColor
                        backgroundOverColor
                        textOverColor
                        displaySendContactButton
                        displayAddMe
                        displayWallet
                        displayFloatingAddMe
                    }
                    icons {
                        socialNetworkColor
                        sectionColor
                        actionIconColor
                        actionIconOverColor
                    }
                    templateOption {
                        angles
                        anglesColor
                        shadow
                        shadowColor
                        displayProfilePicture
                    }
                    separator
                }
                companyLogo
                coverPicture
                information {
                    company {
                        name
                        address
                        address2
                        postalCode
                        city
                        state
                        country
                        phone
                        email
                        website
                        email
                    }
                    office {
                        address
                        address2
                        postCode
                        city
                        state
                        country
                        email
                        phone
                        website
                    }
                    sections {
                        name
                        profiles {
                            firstName
                            lastName
                            title
                            jobTitle
                            phone
                            email
                            link
                            description
                            closingText
                            address
                            website
                        }
                    }
                    medias {
                        youtubeUrl
                        custom {
                            title
                            fileName
                            customUrl
                            mediaGroupName
                        }
                    }
                    socialNetworks {
                        name 
                        externalUrl
                    }
                }
            }
            user {
                active
                title
                lastName
                firstName
                jobTitle
                jobTitleAdditional
                businessEntity
                picture
                email
                departmentUuid
                departmentHeadUuid
                information {
                    contact {
                        phone
                        secondaryPhone
                        other {
                            name
                            id
                        }
                    }
                    biography
                    medias {
                        youtubeUrl
                        custom {
                            title
                            fileName
                            customUrl
                            mediaGroupName
                        }
                    }
                    office {
                        address
                        address2
                        postCode
                        city
                        state
                        country
                        email
                        phone
                        website
                    }
                    sections {
                        name
                        profiles {
                            firstName
                            lastName
                            title
                            jobTitle
                            phone
                            email
                            link
                            description
                            closingText
                            address
                            website
                        }
                    }
                    socialNetworks {
                        name
                        externalUrl
                    }
                }
            }
        }
    }
`

const CREATE_CONTACT = gql`
    mutation publicCreateContact($newContact: ContactInput!) {
        publicCreateContact(newContact: $newContact) {
            information {
                lastName
                firstName
            }
        }
    }
`



const GET_VCF = gql`
    query publicGetVcf($vcardUuid: UUID!) {
        publicGetVcf(vcardUuid: $vcardUuid) {
            title
            data
        }
    }
`

const GET_APPLE_PASS =  gql`
query publicGetApplePass($vcardUuid: UUID!) {
    publicGetApplePass(vcardUuid: $vcardUuid) {
        data
    }
}
`

const GET_GOOGLE_PASS =  gql`
query publicGetGooglePass($vcardUuid: UUID!) {
    publicGetGooglePass(vcardUuid: $vcardUuid) {
        data
    }
}
`

export {GET_FULL_VCARD, CREATE_CONTACT, GET_VCF, GET_APPLE_PASS, GET_GOOGLE_PASS}
