import React from "react"
// MUI
import {Grid, IconButton} from "@mui/material";
import {
    Facebook,
    GitHub, InsertLink,
    Instagram,
    LinkedIn,
    Pinterest,
    Reddit,
    X,
    YouTube
} from "@mui/icons-material";
// Assets
import {ReactComponent as Dailymotion} from "../../../../svg/vcards-social-icons/dailymotion.svg";
import {ReactComponent as Flickr} from "../../../../svg/vcards-social-icons/flickr.svg";
import {ReactComponent as Snapchat} from "../../../../svg/vcards-social-icons/snapchat.svg"
import {ReactComponent as Soundcloud} from "../../../../svg/vcards-social-icons/soundcloud.svg"
import {ReactComponent as Tiktok} from "../../../../svg/vcards-social-icons/tiktok.svg"
import {ReactComponent as Twitch} from "../../../../svg/vcards-social-icons/twitch.svg"
import {ReactComponent as Vimeo} from "../../../../svg/vcards-social-icons/vimeo.svg"

export default function VCardExternalLinksMap(props: any) {

    const {templateLinks, vcardLinks, styles, preview} = props

    const renderLinkIcon = (link: any) => {

        const iconProps = {
            style: {
                ...styles.socialMediaIcons,
            }
        }

        switch (link) {
            case "Other" : return <InsertLink {...iconProps} />
            case "Dailymotion" : return <Dailymotion {...iconProps} />
            case "Facebook": return <Facebook {...iconProps} />
            case "Flickr": return <Flickr {...iconProps} />
            case "Github": return <GitHub {...iconProps} />
            case "Instagram": return <Instagram {...iconProps} />
            case "LinkedIn": return <LinkedIn {...iconProps} />
            case "Pinterest": return <Pinterest {...iconProps} />
            case "Reddit": return <Reddit {...iconProps} />
            case "Snapchat": return <Snapchat {...iconProps} />
            case "Soundcloud": return <Soundcloud {...iconProps} />
            case "Tiktok": return <Tiktok {...iconProps} />
            case "Twitch": return <Twitch {...iconProps} />
            case "Twitter": return <X {...iconProps} />
            case "Vimeo": return <Vimeo {...iconProps} />
            case "Youtube": return <YouTube {...iconProps} />
            default: return <InsertLink {...iconProps} />
        }
    }

    const renderLinkProps = (link: any) => {
        if (preview) return null;
        return {
            component: "a",
            href: link.externalUrl.startsWith("http") ? link.externalUrl : `https://${link.externalUrl}`,
        }
    }

    return (
        <>
            <Grid container justifyContent={"center"}>
                {
                    templateLinks && templateLinks.map((link: any, index: number) => {
                        if (link && link.externalUrl !== "") return (
                            <Grid item key={index}>
                                <IconButton
                                    {...renderLinkProps(link)}
                                    aria-label={"external link"}
                                >
                                    {renderLinkIcon(link.name)}
                                </IconButton>
                            </Grid>
                        )
                    })
                }
                {
                    vcardLinks && vcardLinks.map((link: any, index: number) => {
                        if (link && link.externalUrl !== "") return (
                            <Grid item key={index}>
                                <IconButton
                                    {...renderLinkProps(link)}
                                    aria-label={"external link"}
                                >
                                    {renderLinkIcon(link.name)}
                                </IconButton>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}