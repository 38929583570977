import UAParser from "ua-parser-js";
import i18n from "i18next";

export function getUserAgent() {
    // For documentation about returned properties, see the following: https://www.npmjs.com/package/ua-parser-js
    let parser = new UAParser();
    return parser.getResult();
}

export function getLanguage() {
    return i18n.language
}

export function isYoutubeUrl(url: any) {
    const youtubePublic: string = "/watch?"
    const youtubeEmbed: string = "https://youtube.com/embed/"
    const youtubeShare: string = "https://youtu.be/"

    if (url && url !== "") {
        return !!(url.includes(youtubePublic) || url.includes(youtubeEmbed) || url.includes(youtubeShare));
    }

    return false
}

export function parseYoutubeVideoUrl(url: any) {

    let youtubeRawUrl: any = null
    let youtubeEmbeddingCode: any = null

    const youtubePublic: string = "/watch?"
    const youtubeEmbed: string = "https://youtube.com/embed/"
    const youtubeShare: string = "https://youtu.be/"

    if (url && url !== "") {
        youtubeRawUrl = url

        if (url.includes(youtubePublic)) {
            youtubeEmbeddingCode = youtubeRawUrl.split("/watch?v=").pop().split("&")[0]
        } else if (url.includes(youtubeEmbed)) {
            youtubeEmbeddingCode = youtubeRawUrl.split(youtubeEmbed).pop().split("&")[0]
        } else if (url.includes(youtubeShare)) {
            youtubeEmbeddingCode = youtubeRawUrl.split(youtubeShare).pop().split("&")[0]
        }
    }

    return youtubeEmbeddingCode
}