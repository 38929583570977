import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
// Globals
import './App.css';
// Theme
import {ThemeUnitag} from "./themes/unitagTheme";
import {ThemeProvider} from "@emotion/react";
// Views
import Main from "./views/Main"
import Error from "./views/Error"

function App() {
  return (
      <ThemeProvider theme={ThemeUnitag}>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<Error emptyUuid />} />
              <Route path="/:uuid" element={<Main />} />
              <Route path="/vcard/:id" element={<Main />} />

              <Route path={"*"} element={<Error pathError />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;
