export function callMe(phone: any) {
    window.location.href=`tel:${phone}`
    return
}

export function localizeMe(address: any) {
    window.open(`https://maps.google.com/?q=${address}`)
    return
}

export function emailMe(email: any) {
    window.location.href=`mailto:${email}`
    return
}

export function linkTo(link: any) {
    window.open(`${link}`)
    return
}

export function urlLinkTo(link: any) {
    if (!link) return

    let _link = link
    let hasPrefix = (_link.includes("http://") || _link.includes("https://"))

    if (!hasPrefix) _link = `https://${link}`

    window.open(_link)
    return
}
