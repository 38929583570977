import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
// TRANSLATION FILES
import indexFr from "./locales/fr"
import indexEn from "./locales/en"
import indexEs from "./locales/es"
import indexPt from "./locales/pt"
import indexDe from "./locales/de"

// Namespaces linking with imported JSON files
const resources = {
    en: indexEn,
    fr: indexFr,
    es: indexEs,
    pt: indexPt,
    de: indexDe
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: {
            'en-EN': ['en'],
            'fr-FR': ['en'],
            'es': ['en'],
            'pt': ['en'],
            'de': ['en']
        },
        debug: false,
        interpolation: {
            // may not be useful with React as it escapes by default
            escapeValue: false,
        },
        resources,
        defaultNS: 'common',
        fallbackNS: 'common',
    }).then();

