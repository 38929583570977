import React from "react"
import {useParams} from "react-router-dom";
import Loading from "../components/utils/Loading/Loading";
import {useLazyQuery} from "@apollo/client";
import {GET_FULL_VCARD, GET_VCF, GET_APPLE_PASS, GET_GOOGLE_PASS} from "../api/calls";
import Error from "../views/Error"
import VcardsProvider from "../components/Vcards/Provider/VcardsProvider";
import { Buffer } from 'buffer';

const {REACT_APP_VISIT_URL} = process.env

export default function Main() {

    let queryUuid = useParams().uuid
    let legacyId = useParams().id

    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(false)

    const [fetchVcard, {
        data: vcardData,
        error: vcardError,
        loading: vcardLoading
    }] = useLazyQuery(GET_FULL_VCARD, { fetchPolicy: "no-cache" })


    const [getVcf] = useLazyQuery(GET_VCF, { fetchPolicy: "no-cache" });

    const [getApplePass] = useLazyQuery(GET_APPLE_PASS, { fetchPolicy: "no-cache" });

    const [getGooglePass] = useLazyQuery(GET_GOOGLE_PASS, { fetchPolicy: "no-cache" });


    React.useEffect(() => {
        if (!queryUuid && !legacyId) return

        if (queryUuid !== "") {
            fetchVcard({ variables: { vcardUuid: queryUuid, legacyId: legacyId }})
                .then(() => {
                    setLoading(false)})
                .catch(() => {
                    setLoading(false)
                    setError(true)
                })


            if (REACT_APP_VISIT_URL) fetch(REACT_APP_VISIT_URL + queryUuid).then()
            else fetch("https://eqrco.de/visit/" + queryUuid).then()
        }
    }, [queryUuid,legacyId])

    React.useEffect(() => {
        if (!!queryUuid && !!vcardData?.publicGetVcard?.user) handleManifest(vcardData.publicGetVcard)
    }, [queryUuid, vcardData])

    const handleManifest = (data: any) => {
        let name = data.user.firstName + " " + data.user.lastName
        let picture = data.user.picture
        let allowPicture = Boolean(data.template?.customisation?.templateOption?.displayProfilePicture)
        // Set up manifest manually
        let dynamicManifest = {
            "short_name": `${name}`,
            "name": `vCard Pro - ${name}`,
            "icons": [
                {
                    "src": allowPicture && !!picture ? picture : "unitag192.png",
                    "type": "image/*",
                    "sizes": "any",
                    "purpose": "any"
                },
                {
                    "src": allowPicture && !!picture ? picture : "favicon.ico",
                    "sizes": "64x64 32x32 24x24 16x16",
                    "type": "image/x-icon"
                },
                {
                    "src": allowPicture && !!picture ? picture : "unitag192.png",
                    "type": "image/png",
                    "sizes": "192x192",
                    "purpose": "any"
                },
                {
                    "src": allowPicture && !!picture ? picture : "unitag512.png",
                    "type": "image/png",
                    "sizes": "512x512",
                    "purpose": "any"
                },
                {
                    "src": allowPicture && !!picture ? picture : "unitag192.png",
                    "type": "image/png",
                    "sizes": "192x192",
                    "purpose": "maskable"
                },
                {
                    "src": allowPicture && !!picture ? picture : "unitag512.png",
                    "type": "image/png",
                    "sizes": "512x512",
                    "purpose": "maskable"
                }
            ],
            "start_url": `https://app.vcardpro.io/${queryUuid}`,
            "display": "standalone",
            "theme_color": "#000000",
            "background_color": "#ffffff"
        }

        // change manifest
        const stringManifest = JSON.stringify(dynamicManifest);
        const blob = new Blob([stringManifest], { type: "application/json" });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector("#manifest-json")?.setAttribute("href", manifestURL);


        const icons = allowPicture && !!picture ? picture : "%PUBLIC_URL%/unitag192.png"
        // dynamically change other meta tags for bloody iOS
        document.title = `${name}`
        // document.getElementById("#page-title").innerHTML = `${name}`Ò
        document.querySelector("#apple-touch-icon")?.setAttribute("href", icons);
        document.querySelector("#favicon")?.setAttribute("href", icons);


    }



    const DownloadBlob = (fileName: string, data: string, contentType: string) => {

        let anchor = window.document.createElement('a');
        document.body.appendChild(anchor);

        let blob = new Blob([Buffer.from(data, 'base64')], {type: contentType});
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = fileName;
        anchor.click();

        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
    }

    const downloadVcf = () => {
        let _uuid = vcardData.publicGetVcard.uuid
        getVcf({ variables: { vcardUuid: _uuid }}).then((res: any) => {
            if (res.data.publicGetVcf !== undefined && res.data.publicGetVcf.title !== '' && res.data.publicGetVcf.data !== ''){
                DownloadBlob(res.data.publicGetVcf.title, res.data.publicGetVcf.data, "text/vcard");
            }
        })
    }

    const downloadApplePass = () => {
        let _uuid = vcardData?.publicGetVcard.uuid ? vcardData.publicGetVcard.uuid : queryUuid
        getApplePass({ variables: { vcardUuid: _uuid }}).then((res: any) => {
            if (res.data.publicGetApplePass !== undefined && res.data.publicGetApplePass.title !== '' && res.data.publicGetApplePass.data !== ''){
                DownloadBlob(_uuid+".pkpass", res.data.publicGetApplePass.data, 'application/vnd.apple.pkpass');
            }
        })
    }

    const downloadGooglePass = () => {
        let _uuid = vcardData?.publicGetVcard.uuid ? vcardData.publicGetVcard.uuid : queryUuid
        getGooglePass({ variables: { vcardUuid: _uuid }}).then((res: any) => {
            if (!!res.data.publicGetGooglePass) {
                window.location.href = res.data.publicGetGooglePass.data
            }
        })
    }

    if (loading || vcardLoading) {
        return <Loading vcard />
    }

    if (error || vcardError) {
        return <Error vcardLoadingError />
    }

    if (vcardData) {
        return (
            <>
                <VcardsProvider
                    vcard={vcardData.publicGetVcard}
                    template={vcardData.publicGetVcard.template}
                    {...{downloadVcf, downloadApplePass, downloadGooglePass}}
                />
            </>
        )
    }

    return <></>
}
