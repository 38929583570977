import React from "react"
// mui
import {Paper, Typography} from "@mui/material";
// components
import {SectionAction, SectionCollapse} from "../../index";
// actions
import {callMe, emailMe, localizeMe, urlLinkTo} from "../../../Actions/VcardsActions";

export default function CompanySection(props: any) {

    const {styles, t, templateData, preview, plain} = props;

    let company: any = null

    if (templateData.information.company.name !== "")
        company = templateData.information.company
    else return null

    if (company === undefined || company === null) return null

    if ((company.address == null || company.address.trim() === "") &&
        (company.address2 == null || company.address2.trim() === "") &&
        (company.postalCode == null || company.postalCode.trim() === "") &&
        (company.city == null || company.city.trim() === "") &&
        (company.country == null || company.country.trim() === "") &&
        (company.phone == null || company.phone.trim() === "") &&
        (company.email == null || company.email.trim() === "") &&
        (company.website == null || company.website.trim() === ""))
        return null

    return (
        <Paper sx={styles.paper}>
            <SectionCollapse
                type={"business"}
                title={company.name !== "" ? company.name : t("company")}
                {...{styles, plain, t}}
            >
                <SectionAction
                    action={() => {
                        if (preview) return
                        let _address = `${company?.address} ${company?.address2} ${company?.postalCode} ${company?.city} ${company?.state} ${company?.country}`
                        localizeMe(_address)
                    }}
                    styles={styles}
                    dataName={t("address")}
                    dataContent={(
                        <Typography sx={styles.text}>
                            <b>
                                {(company.address && company.address !== "") && <>{company.address}<br/></>}
                                {(company.address2 && company.address2 !== "") && <>{company.address2}<br/></>}
                                {(company.postalCode && company.postalCode !== "") && <>{company.postalCode}&nbsp;</>}
                                {(company.city && company.city !== "") && <>{company.city}</>}
                                {(company.state && company.state !== "") && <>, {company.state}&nbsp;</>}
                                {(company.country && company.country !== "") && <>, {company.country}</>}
                            </b>
                        </Typography>
                    )}
                    disabled={company.address === ""}
                />
                <SectionAction
                    action={() => {
                        if (preview) return
                        callMe(company.phone)
                    }}
                    styles={styles}
                    dataName={t("phone")}
                    dataContent={company.phone}
                />
                <SectionAction
                    action={() => {
                        if (preview) return
                        emailMe(company.email)
                    }}
                    styles={styles}
                    dataName={t("email")}
                    dataContent={company.email}
                />
                <SectionAction
                    action={() => {
                        if (preview) return
                        urlLinkTo(company.website)
                    }}
                    styles={styles}
                    dataName={t("website")}
                    dataContent={company.website}
                />
            </SectionCollapse>
        </Paper>
    )
}
