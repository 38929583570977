import React from "react"
// mui
import {Divider, Paper, Typography} from "@mui/material";
// components
import {SectionAction, SectionCollapse} from "../../index";
// actions
import {callMe, emailMe, linkTo, localizeMe} from "../../../Actions/VcardsActions";

export default function HelpersSection(props: any) {

    const {styles, t, templateData, vcardData, preview, plain} = props;

    let helpersSections: any

    let hasTemplateSections = (templateData.information.sections && templateData.information.sections.length > 0 )
    let hasVcardSections = (vcardData.information.sections && vcardData.information.sections.length > 0)

    if (hasTemplateSections && hasVcardSections) {
        let _templateSections = templateData.information.sections
        helpersSections = _templateSections.concat(vcardData.information.sections)
    }
    else if (hasTemplateSections) helpersSections = templateData.information.sections
    else if (hasVcardSections) helpersSections = vcardData.information.sections

    const allowProfileDisplay = (profile: any) => {
        return !!(
            profile.firstName ||
            profile.lastName ||
            profile.jobTitle ||
            profile.phone ||
            profile.email ||
            profile.link ||
            profile.description ||
            profile.closingText ||
            profile.address ||
            profile.website
        );
    }

    return (
        <>
            {
                (helpersSections && helpersSections.length > 0) && helpersSections.map((section: any, index: number) => {

                    if (section.profiles && section.profiles.length > 0 && allowProfileDisplay(section.profiles[0])) {
                        return (
                            <Paper sx={{
                                ...styles.paper,
                                py: templateData.base === "service" ? 1 : 2,
                                mt: templateData.base === "service" ? 0 : 1
                            }} key={index}>
                                <SectionCollapse
                                    type={"helper"}
                                    templateType={templateData.templateType}
                                    {...{styles, plain, t}}
                                    title={section.name ? section.name : ""}
                                >
                                    {
                                        (section.profiles && section.profiles.length > 0) && section.profiles.map((profile: any, index: number) => (
                                            <div key={index}>
                                                <SectionAction
                                                    styles={styles}
                                                    forceDisplay
                                                    action={profile.link ? () => { linkTo(profile.link) } : undefined}
                                                    dataName={(
                                                        <Typography sx={styles.text}>
                                                            {(profile.firstName || profile.lastName) &&
                                                                <>
                                                                    <b>
                                                                        {profile.title !== "none" && t(profile.title)}
                                                                        {profile.title !== "none" && " "}
                                                                        {profile.firstName}&nbsp;{profile.lastName}
                                                                    </b>
                                                                    <br/>
                                                                </>}

                                                            {profile.jobTitle && <i>{profile.jobTitle}<br/></i>}
                                                            {!!profile.description && <>{profile.description}</>}
                                                        </Typography>
                                                    )}
                                                />
                                                <SectionAction
                                                    action={() => {
                                                        if (preview) return
                                                        callMe(profile.phone)
                                                    }}
                                                    styles={styles}
                                                    dataName={t("phone")}
                                                    dataContent={profile.phone}
                                                />
                                                <SectionAction
                                                    action={() => {
                                                        if (preview) return
                                                        emailMe(profile.email)
                                                    }}
                                                    styles={styles}
                                                    dataName={t("email")}
                                                    dataContent={profile.email}
                                                />
                                                <SectionAction
                                                    action={() => {
                                                        if (preview) return
                                                        linkTo(profile.website)
                                                    }}
                                                    styles={styles}
                                                    dataName={t("website")}
                                                    dataContent={profile.website}
                                                />
                                                <SectionAction
                                                    action={() => {
                                                        if (preview) return
                                                        localizeMe(profile.address)
                                                    }}
                                                    styles={styles}
                                                    dataName={t("address")}
                                                    dataContent={profile.address}
                                                />

                                                {/* END TEXT */}
                                                {profile.closingText && <Typography sx={styles.text}>{profile.closingText}</Typography>}

                                                {(section.profiles.length > 1 && index+1 < section.profiles.length && templateData.base !== "service") && <Divider sx={{ my: 3 }} />}
                                            </div>
                                        ))
                                    }

                                </SectionCollapse>
                            </Paper>
                        )
                    }

                    return null

                })
            }
        </>
    )
}